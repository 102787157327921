import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

function TourPage() {
	return (
		<Layout>
			<SEO title="360 Tour"/>
			<main className="text-purple-600 container py-4">
				<h1 className="text-lg lg:text-2xl text-center mb-6">360° Tour</h1>

				<iframe
					allowFullScreen="allowfullscreen"
					frameBorder="0"
					height="600px"
					src="https://roundme.com/embed/KSsjdbxsozxy2ulxxeGT"
					style={{display: "block", margin: "6px 0 -12px 0"}}
					title="360 Tour"
					width="100%"
				/>
			</main>
		</Layout>
	)
}

export default TourPage
